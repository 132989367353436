<template>
  <div v-if="item">
    <img
      :src="`/uploads/merchandises/thumbnail/${item.thumbnail}`"
      class="rounded-xl w-full h-64 object-contain mb-3 mx-auto shadow-xl bg-cool-gray-800"
    >
    <div class="mt-4 grid grid-cols-1 gap-4">
      <button
        class="py-3 focus:outline-none bg-level-2 rounded-xl shadow-xl"
        @click="transferConfirmDialog = true"
      >
        <p class="text-teal-400">
          <icon-closet class="h-9 mx-auto" />
        </p>
        <p class="text-teal-100 text-opacity-75 text-sm mt-2">
          この画像で衣装在庫に登録
        </p>
      </button>
    </div>
    <ui-button
      class="w-full mt-4"
      color="error-2"
      @click="deleteConfirmDialog = true"
    >
      削除
    </ui-button>
    <ui-button
      class="w-full mt-3"
      color="secondary"
      @click="$emit('cancel')"
    >
      閉じる
    </ui-button>
    <ui-dialog
      v-model="deleteConfirmDialog"
      title="削除確認"
      type="error"
      description="この画像を削除してもよろしいですか？"
    >
      <ui-button
        color="error"
        class="mb-2"
        @click="deleteMerchandiseImage"
      >
        削除する
      </ui-button>
      <ui-button @click="deleteConfirmDialog = false">
        キャンセル
      </ui-button>
    </ui-dialog>
    <ui-dialog
      v-model="transferConfirmDialog"
      title="衣装在庫に登録しますか？"
      type="question"
    >
      <template #content>
        <p
          class="text-sm leading-5 text-red-500"
        >
          【注意】この物販項目は削除されます
        </p>
      </template>
      <ui-button
        color="primary"
        class="mb-2"
        @click="transferToCostume"
      >
        衣装在庫に登録する
      </ui-button>
      <ui-button @click="transferConfirmDialog = false">
        キャンセル
      </ui-button>
    </ui-dialog>
  </div>
</template>

<script>
import MerchandiseService from '../../services/MerchandiseService';

export default {
  name: 'MerchandiseImageAction',
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isLoading: true,
      deleteConfirmDialog: false,
      transferConfirmDialog: false,
    };
  },
  methods: {
    async transferToCostume() {
      this.transferConfirmDialog = false;
      await MerchandiseService.transferToCostume(this.item.id);
      this.$store.dispatch('notification/set', {
        type: 'success',
        message: '衣装在庫に登録しました！',
      });
      this.$emit('request-update');
      this.$emit('cancel');
    },
    async deleteMerchandiseImage() {
      this.deleteConfirmDialog = false;
      await MerchandiseService.removeImage(this.item.id);
      this.$store.dispatch('notification/set', {
        type: 'success',
        message: '画像を削除しました！',
      });
      this.$emit('request-update');
      this.$emit('cancel');
    },
  },
};
</script>
