<template>
  <div class="my-4 border border-gray-800 border-opacity-50 rounded-xl px-4 py-3">
    <div class="flex items-start">
      <div class="flex-grow">
        <div v-if="item.merchandise_category.id === 3" class="mb-2">
          <span
            class="text-xxs inline-block px-3 py-1 rounded-xl"
            :class="item.label ? 'bg-yellow-700 text-yellow-50' : 'bg-teal-800 text-teal-200'"
            >{{ item.label ? item.label.name : '全体' }}</span
          >
        </div>
      </div>
      <button
        v-if="item.merchandise_category.id === 3"
        class="w-5 text-right flex-shrink-0 focus:outline-none"
        @click="$emit('click-edit', item.id)"
      >
        <font-awesome-icon class="text-sub text-opacity-75 text-xs" :icon="['fas', 'pen']" />
      </button>
    </div>
    <p class="text-sub text-xxs tracking-widest">
      {{ item.actresses.map(actress => actress.name).join('・') }}
    </p>
    <h2 class="text-main">
      {{ item.name || item.merchandise_category.name }}
    </h2>
    <p v-if="item.order_item" class="truncate my-0.5">
      <a class="text-purple-400 underline text-xs" :href="item.order_item.url" target="_blank"
        >購入先</a
      >
    </p>
    <div v-if="item.merchandise_amount" class="mt-3">
      <ui-number-input v-model="item.merchandise_amount.amount" :min="0" @change="updateAmount" />
    </div>
    <div v-if="item.merchandise_category.id === 3" class="mt-3">
      <div v-if="item.merchandise_images.length" class="grid grid-cols-3 gap-2">
        <img
          v-for="image in item.merchandise_images"
          :key="image.id"
          v-lazy="`/uploads/merchandises/thumbnail/${image.thumbnail}`"
          class="w-full h-28 object-cover rounded-xl shadow-xl"
          @click="$emit('action-merchandise-image', image)"
        />
      </div>
      <div v-else class="p-4 bg-level-2 text-sm text-sub rounded-xl">
        登録されている画像はありません
      </div>
      <ui-file-upload
        target="merchandises"
        :target-id="item.id"
        class="mt-2"
        button-class="w-full text-sm"
        multiple
        @upload-begin="uploading = true"
        @upload-end="
          uploading = false
          reload()
        "
      >
        <font-awesome-icon v-if="uploading" :icon="['fas', 'circle-notch']" spin />
        <template v-else>
          アップロード
        </template>
      </ui-file-upload>
    </div>
    <div class="mt-3">
      <ui-checkbox
        v-for="todo in todoList"
        :key="todo.id"
        :label="todo.name"
        class="mb-1"
        :value="!!completedTodoItems.find(item => item.merchandise_todo_item_id === todo.id)"
        @change="toggleCheckbox(todo.id, $event)"
      />
    </div>
  </div>
</template>

<script>
import MerchandiseService from '../../services/MerchandiseService'

export default {
  name: 'MerchandiseItem',
  props: {
    item: {
      type: Object,
      required: true,
    },
    todoList: {
      type: Array,
      required: true,
    },
    completedTodoItems: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    uploading: false,
  }),
  methods: {
    async reload() {
      this.$emit('request-reload', this.item.id)
    },
    async updateAmount(value) {
      await MerchandiseService.updateAmount(this.item.merchandise_amount.id, value)
    },
    async toggleCheckbox(todoId, value) {
      if (value) {
        await MerchandiseService.checkTodo(this.item.id, todoId)
      } else {
        const target = this.completedTodoItems.find(
          item => item.merchandise_todo_item_id === todoId,
        )
        if (!target) return
        await MerchandiseService.uncheckTodo(target.id)
      }
    },
  },
}
</script>
