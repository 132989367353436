<template>
  <div>
    <ui-transition>
      <div v-if="currentSchedule">
        <div
          class="flex justify-between rounded-2xl items-center px-4 py-3 mb-4 bg-level-1 shadow-xl"
        >
          <img
            v-if="currentSchedule.thumbnail"
            v-lazy="`/uploads/schedules/${currentSchedule.thumbnail}`"
            class="w-14 h-14 rounded-xl shadow-xl object-cover flex-shrink-0"
          />
          <img
            v-else
            v-lazy="`/uploads/actresses/${currentSchedule.actresses[0].thumbnail}`"
            class="w-14 h-14 rounded-xl shadow-xl object-cover flex-shrink-0"
          />
          <div class="flex-grow pl-3">
            <ui-select
              v-model="currentScheduleId"
              :options="schedules"
              label="撮影を選択"
              placeholder="撮影を変更"
              light
            />
          </div>
        </div>
        <ui-tab v-model="activeTabIndex" :items="['唾液など', '衣装']" />
      </div>
    </ui-transition>
    <ui-transition group>
      <div v-if="todoList.size && !isLoading && activeTabIndex === 0" key="others">
        <div v-if="otherMerchandises.length">
          <merchandise-item
            v-for="merchandise in otherMerchandises"
            :key="merchandise.id"
            :item="merchandise"
            :todo-list="todoList.get(merchandise.merchandise_category.id) || []"
            :completed-todo-items="merchandise.merchandise_completed_todo_items"
          />
        </div>
        <div v-else class="text-center text-sub text-opacity-75 py-6 bg-level2 text-sm">
          登録されているアイテムはありません
        </div>
      </div>
      <div v-else-if="todoList.size && !isLoading && activeTabIndex === 1" key="costumes">
        <div v-if="costumeMerchandises.length">
          <merchandise-item
            v-for="merchandise in costumeMerchandises"
            :key="merchandise.id"
            :item="merchandise"
            :todo-list="todoList.get(merchandise.merchandise_category.id) || []"
            :completed-todo-items="merchandise.merchandise_completed_todo_items"
            @click-edit="
              editDialog = true
              editedId = $event
            "
            @action-merchandise-image="showMerchandiseImageAction"
            @request-reload="refetchCostume($event)"
          />
        </div>
        <div v-else class="text-center text-sub text-opacity-75 py-6 bg-level2 text-sm">
          登録されているアイテムはありません
        </div>
      </div>
    </ui-transition>
    <ui-action v-model="actionVisible">
      <merchandise-image-action
        :item="activeMerchandiseImage"
        @request-update="refetchCostumeByMerchandiseImageId"
        @cancel="actionVisible = false"
      />
    </ui-action>
    <merchandise-edit-dialog
      v-model="editDialog"
      :merchandise-id="editedId"
      :schedule-id="currentScheduleId"
      @saved="refetchCostume($event)"
    />
  </div>
</template>

<script>
import MerchandiseItem from '../components/merchandise/MerchandiseItem.vue'
import MerchandiseImageAction from '../components/merchandise/MerchandiseImageAction.vue'
import MerchandiseEditDialog from '../components/merchandise/MerchandiseEditDialog.vue'
import MerchandiseService from '../services/MerchandiseService'
import ScheduleService from '../services/ScheduleService'
import { endOfDay } from 'date-fns'

export default {
  name: 'Merchandise',
  components: {
    MerchandiseItem,
    MerchandiseImageAction,
    MerchandiseEditDialog,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    initLoadCompleted: false,
    isLoading: true,
    currentSchedule: null,
    costumeMerchandises: [],
    otherMerchandises: [],
    schedules: [],
    todoList: new Map(),
    actionVisible: false,
    activeMerchandiseImage: null,
    editDialog: false,
    editedId: 0,
  }),
  computed: {
    activeTabIndex: {
      get() {
        return this.$store.getters['app/merchandiseActiveTabIndex']
      },
      set(value) {
        this.$store.commit('app/merchandiseActiveTabIndex', value)
      },
    },
    currentScheduleId: {
      get() {
        return this.$store.getters['app/merchandiseActiveScheduleId']
      },
      set(value) {
        this.$store.commit('app/merchandiseActiveScheduleId', value)
      },
    },
  },
  watch: {
    id() {
      this.initLoadCompleted = false
      this.fetch()
    },
    currentScheduleId(value) {
      if (!this.initLoadCompleted) {
        return
      }
      this.$router.replace(`/merchandise_todo/${value}`)
    },
    watch: {
      actionVisible(value) {
        if (!value) {
          this.activeMerchandiseImage = null
        }
      },
    },
  },
  async created() {
    const [todoList, schedules] = await Promise.all([
      MerchandiseService.getTodoList(),
      ScheduleService.getNear(),
    ])
    this.schedules = schedules.map(schedule => ({
      value: schedule.id,
      text: schedule.title,
    }))
    this.todoList = todoList.reduce((p, c) => {
      if (!p.has(c.merchandise_category_id)) {
        p.set(c.merchandise_category_id, [])
      }
      p.get(c.merchandise_category_id).push({ id: c.id, name: c.name })
      return p
    }, new Map())
    await this.fetch()
  },
  methods: {
    async fetch() {
      this.isLoading = true
      let merchandise, schedule
      if (
        this.id === 'default' &&
        this.currentScheduleId &&
        this.schedules.find(v => v.value === this.currentScheduleId) &&
        this.$store.getters['app/merchandiseActiveScheduleExpiresAt'] &&
        Date.parse(this.$store.getters['app/merchandiseActiveScheduleExpiresAt']) >
          new Date().getTime()
      ) {
        const res = await MerchandiseService.get(this.currentScheduleId)
        merchandise = res.merchandise
        schedule = res.schedule
      } else {
        const res = await MerchandiseService.get(this.id)
        merchandise = res.merchandise
        schedule = res.schedule
      }
      this.currentSchedule = schedule
      this.currentScheduleId = schedule.id
      this.$store.commit(
        'app/merchandiseActiveScheduleExpiresAt',
        endOfDay(new Date()).toISOString(),
      )
      const { others, costumes } = merchandise.reduce(
        (p, c) => {
          if (c.merchandise_category.id === 3) {
            p.costumes.push(c)
          } else {
            p.others.push(c)
          }
          return p
        },
        { others: [], costumes: [] },
      )
      this.costumeMerchandises = costumes
      this.otherMerchandises = others
      this.isLoading = false
      this.$nextTick(() => {
        this.initLoadCompleted = true
      })
    },
    async refetchCostume(id) {
      const targetIndex = this.costumeMerchandises.findIndex(item => item.id === id)
      const item = await MerchandiseService.getOne(id)
      if (!item) {
        this.$delete(this.costumeMerchandises, targetIndex)
        return
      }
      if (targetIndex === -1) {
        this.costumeMerchandises.push(item)
      } else {
        this.$set(this.costumeMerchandises, targetIndex, item)
      }
    },
    async refetchCostumeByMerchandiseImageId() {
      if (!this.activeMerchandiseImage) return
      const target = this.costumeMerchandises.find(item =>
        item.merchandise_images.find(image => image.id === this.activeMerchandiseImage.id),
      )
      if (target) {
        this.refetchCostume(target.id)
      }
    },
    showMerchandiseImageAction(merchandiseImage) {
      this.actionVisible = true
      this.activeMerchandiseImage = merchandiseImage
    },
    createMerchandise() {
      this.editedId = 0
      this.editDialog = true
    },
  },
}
</script>
